import { component } from './';
const request = component('/ticketsV2');

const sendTicketV2 = async (groupId, title, content, email, isReport = false) => {
    const data = {
      groupId,
      title,
      content,
      email,
      isReport
    };

    const result = await request('/sendTicket', data);
    return result;
};


export default { sendTicketV2 };