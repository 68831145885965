
import style from './style.module.css';

export default function Loader({label}){
  return (
    <div className={style.loaderWrapper}>
      <div className={style.loader}></div>
      <div className={style.welcomeMessage}>
        <h1>PuzzleConnect+</h1>
        <p>Please hang on while we load the experience for you</p>
        <p>{label}</p>
      </div>
    </div>
  )
}